<template>
  <popup-modal ref="popup">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="_cancel">
        <div class="modal-container" @click.stop>
          <div class="modal-header">
            <slot name="header"> Forgot Password </slot>
            <div class="close-wrapper" @click="_cancel">
              <svg class="icon">
                <use xlink:href="#close"></use>
              </svg>
            </div>
          </div>

          <div class="modal-body">
            <form action="#" @submit.prevent="doForgotPassword">
              <div
                class="form-group"
                :class="{ error: v$.form.EmailAddress.$errors.length }"
              >
                <label
                  >Email Address <span class="required-star">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="v$.form.EmailAddress.$model"
                />
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.form.EmailAddress.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
                <div class="input-errors" v-if="!isSuccessful">
                  <div class="error-msg">
                    {{ resmessage }}
                  </div>
                </div>
              </div>
              <button class="main-btn">Send my password</button>
              <div class="resmessage" v-if="isSuccessful">
                {{ resmessage }}
              </div>
            </form>
          </div>

          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";
import { dataProvider } from "@/api/dataProvider";
import useVuelidate from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";

export default {
  name: "ConfirmDialogue",

  components: { PopupModal },

  data() {
    return {
      // Parameters that change depending on the type of dialogue
      v$: useVuelidate(),
      form: {
        EmailAddress: "",
      },
      filterName: "",
      title: undefined,
      message: undefined, // Main text content
      okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
      cancelButton: "Cancel", // text for cancel button

      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined,
      resmessage: "",
      isSuccessful: false,
      smessage: false,
    };
  },
  validations() {
    return {
      form: {
        EmailAddress: {
          required: helpers.withMessage("Email address is required", required),
          email: helpers.withMessage("Please enter a valid email", email),
        },
      },
    };
  },
  computed: {
    disableOkButton() {
      if (this.filterName.length) {
        return false;
      } else return true;
    },
  },
  methods: {
    async doForgotPassword() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      var forgotPassword = await dataProvider.postEmailAddress(
        this.form.EmailAddress
      );

      this.resmessage = forgotPassword.ResponseText;
      this.isSuccessful = forgotPassword.IsSuccessful;

      setTimeout(() => (this.resmessage = ""), 5000);
      setTimeout(() => (this.isSuccessful = false), 5000);
      setTimeout(() => this.$refs.popup.close(), 5000);
    },
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.filterName = opts.filterName;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(this.filterName);
      this.$$router.push("/login");
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise("");
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>

<style lang="scss" scoped>
.inputBox {
  border: 2px solid black;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #5858c7;
  color: white;
  border: 2px solid #0ec5a4;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  padding: 35px 30px;
  background-color: #d7d7d7;
  border-radius: 10px;
  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
}

.modal-header {
  margin-top: 0;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.modal-body {
  margin: 10px 0;
  label {
    display: inline-block;
    margin-bottom: 5px;
    color: #3c3c3c;
  }
  .form-control {
    margin-bottom: 5px;
  }
  .input-errors {
    margin-top: 4px;
    font-size: 14px;
    color: #ff073a;
  }
}

.cancel-btn:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.33);
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.close-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
}
.resmessage {
  background: #5ad568ee;
  color: #fff;
  padding: 8px 20px;
  margin-top: 10px;
  border-radius: 6px;
}
@media (min-width: 992px) {
  .modal-container {
    width: 500px;
  }
}
</style>
